import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import styled from "styled-components";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import * as calculatorService from '../services/calculatorService';
import Helmet from "react-helmet"

const Table = styled.table`
  thead,
  tr,
  tbody {
    display: block;
  }
  thead tr .font-weight-100 {
    font-weight: 100 !important;
  }
  thead tr .sin-title-box {
    padding: 0 20px;
    height: 60px;
    color: #19191b;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.56px;
  }
  .title-col {
    min-width: 290px;
  }
  .basic-col,
  .standard-col,
  .premium-col,
  .ent-col {
    min-width: 260px;
  }

  .colored-row {
    background: #f7f7fb;
    border-top: 2px solid #fff;
    &:first-child {
      border-top: 0;
    }
    .sin-box.title-col {
      color: #19191b;
      font-weight: 700;
    }
    .sin-box {
      position: relative;
      padding: 0 20px;
      height: 65px;
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      .table-icon {
        font-size: 20px;
        &.neg {
          color: #f04037;
        }
        &.pos {
          color: #67d216;
        }
      }
      &:before {
        content: "";
        background: #fff;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 1px;
      }
    }
  }
`;

const ButtonSm = styled(Button)`
  width: 167px;
  min-width: auto;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.secondary}`} !important;
    background-color: ${({ theme }) => `${theme.colors.secondary}`} !important;
    color: #fff;
  }
`;


const Calculator = () => {
  const [numberOfEnvelopes, setNumberOfEnvelopes] = useState(5);
  const [openAndScan, setOpenAndScan] = useState("No");
  const [numberOfPages, setNumberOfPages] = useState(2);
  const [shredOrForward, setShredOrForward] = useState("Forwarded");
  const [domestic, setDomestic] = useState("Within Australia");
  const [howOften, setHowOften] = useState("Monthly");
  const [result, setResults] = useState([]);
  const [previewScan, setPreviewScan] = useState("Yes");

  const onClick = async () => {
    var result = await calculatorService.calculateMail(numberOfEnvelopes,
      openAndScan === "Yes",
      numberOfPages,
      shredOrForward === "Forwarded",
      domestic === "Within Australia",
      howOften,
      previewScan === "Yes"
    );
    setResults(result);
  }
  const updatePreviewScan = (value) => {
    setPreviewScan(value);
    if (value === "No"){
      setOpenAndScan("Yes");
    }
  } 

  return (
    <>
      <Helmet>
        <title>HotSnail - Mail Pricing Calculator</title>
        <meta name="title" content="HotSnail - Mail Pricing Calculator" />
        <meta name="description" content="HotSnail - Mail pricing calculator - calculate the price of mails scanning & forwarding " />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail - Mail Pricing Calculator" />
        <meta property="og:description" content="HotSnail - Mail pricing calculator - calculate the price of mails scanning & forwarding " />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://hotsnail.com.au/" />
        <meta property="twitter:title" content="HotSnail - Mail Pricing Calculator" />
        <meta property="twitter:description" content="HotSnail - Mail pricing calculator - calculate the price of mails scanning & forwarding " />
        <meta property="twitter:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />
      </Helmet>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="6">
                <Title variant="hero">Mail cost estimator</Title>
              </Col>
            </Row>

            <Section className="pt-0">
              <Container>
                <div
                  css={`
              overflow: auto;
              padding-bottom: 30px;
            `}
                >

                  <Form>
                    <Form.Group>
                      <Form.Label>Estimated number of envelopes per month</Form.Label>
                      <Form.Control type="number" value={numberOfEnvelopes || ""} onChange={(e) => setNumberOfEnvelopes(e.target.value)} />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Will you require a preview scan?</Form.Label>
                      <Form.Control as="select" value={previewScan} onChange={(e) => updatePreviewScan(e.target.value)} >
                        <option>Yes</option>
                        <option>No</option>
                      </Form.Control>
                      <Form.Text className="text-muted">
                        Preview scans may prevent you from opening and scanning every document unnecessarily, or you may want to avoid the preview scan fee.
                      </Form.Text>
                    </Form.Group>

                      <Form.Group>
                        <Form.Label>Will you have us open and scan the contents?</Form.Label>
                        <Form.Control as="select" value={openAndScan} onChange={(e) => setOpenAndScan(e.target.value)} disabled={previewScan === "No"}>
                          <option>Yes</option>
                          <option>No</option>
                        </Form.Control>
                      </Form.Group>

                    {openAndScan === "Yes" &&
                      <Form.Group>
                        <Form.Label>Estimated number of pages per envelope</Form.Label>
                        <Form.Control type="number" value={numberOfPages} onChange={(e) => setNumberOfPages(e.target.value)} />
                      </Form.Group>
                    }

                    <Form.Group>
                      <Form.Label>Will the majority of envelopes be forwarded or shredded?</Form.Label>
                      <Form.Control as="select" value={shredOrForward} onChange={(e) => setShredOrForward(e.target.value)} >
                        <option>Forwarded</option>
                        <option>Shredded</option>
                      </Form.Control>
                      <Form.Text className="text-muted">
                        You may not need the items forwarded if we have scanned the contents
                      </Form.Text>
                    </Form.Group>

                    {shredOrForward === "Forwarded" &&
                      <Form.Group>
                        <Form.Label>Will the envelopes be forwarded within Australia or internationally?</Form.Label>
                        <Form.Control as="select" value={domestic} onChange={(e) => setDomestic(e.target.value)} >
                          <option>Within Australia</option>
                          <option>Internationally</option>
                        </Form.Control>
                      </Form.Group>
                    }

                    {shredOrForward === "Forwarded" &&
                      <Form.Group>
                        <Form.Label>How often will you forward the mail?</Form.Label>
                        <Form.Control as="select" value={howOften} onChange={(e) => setHowOften(e.target.value)} >
                          <option value="Daily">As soon as it arrives</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                          Bundling forwards can cut down on forwarding costs
                      </Form.Text>
                      </Form.Group>
                    }

                    <Button variant="primary" onClick={onClick}>
                      Estimate
                    </Button>
                  </Form>


                  {(result && result.length > 0) &&
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col" className="title-col sin-title-box"></th>
                          {result.map(a => <th key={a.planName} scope="col" className="basic-col sin-title-box">{a.planName}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="colored-row">
                          <th scope="row" className="title-col sin-box">
                            Total Estimate (per month)
                          </th>

                          {result.map(a => <td className="basic-col sin-box font-weight-bold">${a.price.toFixed(2)}</td>)}
                        </tr>
                        <tr className="colored-row">
                          <th scope="row" className="title-col sin-box" style={{fontWeight: "100"}}>
                            Monthly Fee
                          </th>

                          {result.map(a => <td className="basic-col sin-box">${a.monthlyFee.toFixed(2)}</td>)}
                        </tr>
                        <tr className="colored-row">
                          <th scope="row" className="title-col sin-box" style={{fontWeight: "100"}}>
                            Forward Cost
                          </th>
                          {result.map(a => <td className="basic-col sin-box">${a.forwardFee.toFixed(2)}</td>)}
                        </tr>
                        <tr className="colored-row">
                          <th scope="row" className="title-col sin-box" style={{fontWeight: "100"}}>
                            Preview Scan Cost
                          </th>
                          {result.map(a => <td className="basic-col sin-box">${a.previewScan.toFixed(2)}</td>)}
                        </tr>
                        <tr className="colored-row">
                          <th scope="row" className="title-col sin-box" style={{fontWeight: "100"}}>
                            Content Scan Cost
                          </th>
                          {result.map(a => <td className="basic-col sin-box">${a.scanContents.toFixed(2)}</td>)}
                        </tr>
                        <tr className="colored-row">
                          <th scope="row" className="title-col sin-box" style={{fontWeight: "100" }}>
                            Shredding Cost
                          </th>
                          {result.map(a => <td className="basic-col sin-box">${a.shredFee.toFixed(2)}</td>)}
                        </tr>
                        <tr>
                          <td colSpan="4"><small>Price is estimate only &amp; excludes once off setup fee</small></td>
                        </tr>

                      </tbody>
                    </Table>
                  }
                </div>
              </Container>
            </Section>

          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Calculator;
